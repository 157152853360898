export const getUrlParam = (data, param) => {
    if (data) {
        const search = new URLSearchParams(data)
        const code = search.get(param)
        if (code) {
            return code
        }
    }
    return false
}
