import React from 'react'
import { content, wrapper } from './style.module.scss'

const ConnectingLoader = ({ process }) => {
    return (
        <div className={wrapper}>
            <p className={content}>{process}</p>
        </div>
    )
}

export default ConnectingLoader
