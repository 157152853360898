import React from 'react'
import { navigate } from 'gatsby'
import ConnectingLoader from 'Components/ConnectingLoader'
import { useNotifications } from 'Hooks/useNotifications'
import { useGetStravaAuthQuery } from 'State/services/strava'
import { getUrlParam } from 'Utils/getUrlParam'
import { isBrowser } from 'Utils/isBrowser'

const StravaRedirect = ({ location }) => {
    if (!isBrowser()) {
        return null
    }

    const code = getUrlParam(location.search, 'code')

    if (!code) {
        navigate('/')
    }

    const notification = useNotifications()
    const { data, isLoading } = useGetStravaAuthQuery(code)

    // Check required scope is correct. If not, set notification to message
    // and return to homepage.
    //
    // If access not given return to homepage

    if (!isLoading) {
        navigate('/')
    }

    // Update to standardised access page for all third parties
    return <ConnectingLoader process={notification} />
}

export default StravaRedirect
